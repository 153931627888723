import React from 'react';
import ProjectCard from '../components/ProjectCard';

const projects = [
    {
        id: 1,
        title: 'GradeBookPro',
        description: 'A comprehensive Learning Management System built with React and Firebase, offering course management for students, teachers, and administrators. The application optimizes content delivery, fosters collaboration, and simplifies student progress tracking.',
        technologies: ['React', 'Firebase', 'JavaScript', 'CSS'],
        type: 'Educational Technology',
    }
];

function Projects() {
  return (
    <div className="projects">
      <h2>My Projects</h2>
      <div className="project-grid">
        {projects.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
    </div>
  );
}

export default Projects;