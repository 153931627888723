import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home">
      <div className="animated-bg">
        <div className="shape circle1"></div>
        <div className="shape circle2"></div>
        <div className="shape triangle1"></div>
        <div className="shape triangle2"></div>
        <div className="shape square1"></div>
        <div className="shape square2"></div>
      </div>
      <h2>Welcome to My Tech Portfolio</h2>
      <p>Educational Technology Specialist with a strong foundation in English language and literature. Experienced in developing learning tools, implementing data-driven strategies, and enhancing educational solutions that support language learning. Combines technical expertise with exceptional communication skills to create effective and engaging learning environments.</p>
      <div className="cta-buttons">
        <Link to="/projects" className="cta-button">View My Projects</Link>
        <Link to="/skills" className="cta-button">See My Skills</Link>
      </div>
    </div>
  );
}

export default Home;