import React from 'react';
import SkillCard from '../components/SkillCard';

const skillSets = [
  {
    category: 'Educational Technology',
    skills: [
      'Learning Management Systems (LMS)',
      'Digital Learning Tools',
      'Remote Learning Solutions',
      'Educational Content Development',
      'Student Progress Tracking',
      'Interactive Learning Resources',
      'Educational Analytics'
    ],
  },
  {
    category: 'Technical Skills',
    skills: [
      'React',
      'React Native',
      'Firebase',
      'JavaScript',
      'HTML/CSS',
      'Node.js',
      'C++',
      'C#',
      'SQL'
    ],
  },
  {
    category: 'Professional Skills',
    skills: [
      'Strategic Communications',
      'Project Management',
      'Technical Writing',
      'Curriculum Integration',
      'Stakeholder Collaboration',
      'Data Analysis',
      'User Training'
    ],
  }
];

function Skills() {
  return (
    <div className="skills">
      <h2>My Skills</h2>
      <div className="skills-grid">
        {skillSets.map((skillSet, index) => (
          <SkillCard key={index} {...skillSet} />
        ))}
      </div>
    </div>
  );
}

export default Skills;