import React from 'react';
import { useParams } from 'react-router-dom';

const projects = {
    1: {
        title: 'GradeBookPro',
        description: 'A Learning Management System built with React and Firebase that organizes courses through an intuitive online interface. The system manages user authentication, stores data, and shows real-time updates. With role-based access for students, teachers, and administrators, users can access courses, view lessons, manage assignments, and participate in discussions.',
        challenge: 'Create a Learning Management System that can handle real-time updates, secure file management, and maintain data privacy while providing an intuitive interface for all users.',
        solution: 'The system architecture separates concerns into distinct modules for authentication, course interactions, assignments, announcements, and forums. The frontend uses React for rendering views and managing real-time updates through event handlers and React hooks. The backend leverages Firebase for authentication, data storage in Firestore, and file handling in Firebase Storage. This modular design allows for future updates without disrupting the existing architecture.',
        impact: 'The project successfully delivers a secure, scalable, and user-friendly LMS that enhances educational delivery and management. The testing phase confirmed system performance with no critical bugs or major usability issues, while the modular architecture ensures easy maintenance and future expandability.',
        technologies: ['React', 'Firebase Authentication', 'Firestore', 'Firebase Storage', 'Real-time Database', 'React Hooks', 'JavaScript'],
        type: 'Educational Technology',
        features: {
            frontend: [
                'React components for role-specific dashboards',
                'Event handlers and React hooks for state management',
                'Real-time interface updates',
                'Responsive design for cross-device compatibility'
            ],
            backend: [
                'Firebase Authentication for secure access control',
                'Firestore for course data, user accounts, and enrollment',
                'Firebase Storage for assignment submissions and course materials',
                'Real-time database for instant updates across users'
            ]
        },
        roleFeatures: {
            admin: [
                'Full system oversight',
                'User and course management',
                'Role assignment and modification',
                'System-wide announcements'
            ],
            teacher: [
                'Course content creation',
                'Assignment management and grading',
                'Course announcements',
                'Forum moderation'
            ],
            student: [
                'Course material access',
                'Assignment submission',
                'Grade tracking',
                'Forum participation'
            ]
        },
        demoAccess: {
            note: 'For testing purposes, the following demo accounts are available (password for all accounts: "password"):',
            adminNote: 'Note: Admin accounts cannot be created through the application interface - you must use the pre-configured admin account provided above.',
            accounts: [
                { role: 'Admin', email: 'dulerio@ramapo.edu' },
                { role: 'Teacher', email: 'teacher@test.test' },
                { role: 'Student', email: 'student@test.test' }
            ]
        },
        demoLink: 'https://mylms-928484.web.app/'
    }
};

function ProjectDetail() {
    const { id } = useParams();
    const project = projects[id];

    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <div className="project-detail">
            <h2>{project.title}</h2>
            <p><strong>Type:</strong> {project.type}</p>
            
            <div className="project-links">
                {project.demoLink && (
                    <a href={project.demoLink} target="_blank" rel="noopener noreferrer" className="project-link">
                        Live Demo
                    </a>
                )}
            </div>

            <h3>Overview</h3>
            <p>{project.description}</p>

            <h3>Demo Access</h3>
            <p>{project.demoAccess.note}</p>
            <p><em>{project.demoAccess.adminNote}</em></p>
            <div className="project-section">
                {project.demoAccess.accounts.map((account, index) => (
                    <span key={index} className="demo-account">
                        {account.role}: {account.email}
                    </span>
                ))}
            </div>

            <h3>Technical Implementation</h3>
            <div className="project-section">
                <div className="role-section">
                    <h4>Frontend</h4>
                    <ul className="feature-list">
                        {project.features.frontend.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </div>
                
                <div className="role-section">
                    <h4>Backend</h4>
                    <ul className="feature-list">
                        {project.features.backend.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <h3>Challenge</h3>
            <p>{project.challenge}</p>

            <h3>Solution</h3>
            <p>{project.solution}</p>

            <h3>Impact</h3>
            <p>{project.impact}</p>

            <h3>Technologies Used</h3>
            <ul className="pill-list">
                {project.technologies.map((tech, index) => (
                    <li key={index}>{tech}</li>
                ))}
            </ul>

            <h3>Key Features by Role</h3>
            <div className="project-section">
                <div className="role-section">
                    <h4>Administrator</h4>
                    <ul className="feature-list">
                        {project.roleFeatures.admin.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </div>
                
                <div className="role-section">
                    <h4>Teacher</h4>
                    <ul className="feature-list">
                        {project.roleFeatures.teacher.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </div>
                
                <div className="role-section">
                    <h4>Student</h4>
                    <ul className="feature-list">
                        {project.roleFeatures.student.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ProjectDetail;